<template>
  <div>
    <v-tabs-items
      v-if="false"
      v-model="selectedAppBarTab"
      style="height: 100%;"
    >
      <v-tab-item
        v-for="tab in tabs"
        :value="tab.key"
        :key="tab.key"
        style="height: 100%;"
      >
        <v-row style="height: 100vh;">
          <v-col
            class="pr-0 pt-0"
            cols="12"
            md="4"
            style="border-right: 1px solid #ddd;"
          >
            <v-card
              tile
              flat
              max-height="100vh"
              class="overflow-y-auto"
            >
              <CouponList
                class="pt-5"
                v-if="tab.key === 'active'"
                :length="limit"
                :step="limit"
                @selectCoupon="selectCoupon"
                :load-items-callback="getCoupons"
                :fixed-filters="activeFixedFilters"
                :call-to-actions="callToActions"
              />
              <CouponList
                class="pt-5"
                v-if="tab.key === 'inactive'"
                :length="limit"
                :step="limit"
                @selectCoupon="selectCoupon"
                :load-items-callback="getCoupons"
                :fixed-filters="inactiveFixedFilters"
                :call-to-actions="callToActions"
              />
              <CouponList
                class="pt-5"
                v-if="tab.key === 'fully_redeemed'"
                :length="limit"
                :step="limit"
                @selectCoupon="selectCoupon"
                :load-items-callback="getCoupons"
                :fixed-filters="fullyRedeemedFixedFilters"
                :call-to-actions="callToActions"
              />
              <CouponList
                class="pt-5"
                v-if="tab.key === 'unused'"
                :length="limit"
                :step="limit"
                @selectCoupon="selectCoupon"
                :load-items-callback="getCoupons"
                :fixed-filters="unusedFixedFilters"
                :call-to-actions="callToActions"
              />
              <!--   <CouponList Requires proper filter functionality in actionList
                  class="pt-5"
                  v-if="tab.key === 'fully_redeemed'"
                  :length="limit"
                  :step="limit"
                  @selectCoupon="selectCoupon"
                  :load-items-callback="getCoupons"
                  :fixed-filters="fullyRedeemedFixedFilters"
                /> -->
            </v-card>
          </v-col>
          <v-col
            class="grey lighten-3"
            cols="8"
            v-if="!$vuetify.breakpoint.xsOnly"
          >
            <CouponProfile
              class="pr-3 overflow-y-auto"
              style="max-height: 100vh"
              :coupon="selectedCoupon"
            />
          </v-col>
        </v-row>
      </v-tab-item>
      <v-bottom-sheet
        v-if="$vuetify.breakpoint.xsOnly"
        v-model="showBottomSheet"
      >
        <CouponProfile
          class="grey lighten-3 mb-3 overflow-y-auto"
          :coupon="selectedCoupon"
          style="max-height: 100vh"
        />
      </v-bottom-sheet>
    </v-tabs-items>
    <ExtendedView
      :tabs="tabs"
      :selected-object="selectedCoupon"
    >
      <template v-slot:list="{ item }">
        <CouponList
          v-if="item"
          :fixed-filters="fixedFilters(item.key)"
          :load-items-callback="getCoupons"
          :call-to-actions="callToActions"
          @selectCoupon="selectCoupon"
        />
      </template>
      <template v-slot:profile>
        <CouponProfile
          :coupon="selectedCoupon"
        />
      </template>
    </ExtendedView>
    <CouponDialog
      v-model="addNewCoupon"
      v-if="addNewCoupon"
      @closed="dialogClosed"
      @couponCreated="couponCreated"
    />
    <CouponCreateStepper
      v-if="addMultipleCoupons"
      @closed="addMultipleCoupons = false"
    />
  </div>
</template>

<script>

  import CouponList from "@/components/CouponList"
  import CouponProfile from "@/components/coupon/CouponProfile"
  import ExtendedView from "@/components/common/ExtendedView"
  import CouponDialog from "@/components/CouponDialog"
  import CouponCreateStepper from "@/components/coupon/CouponCreateStepper"

  export default {
    name: 'Coupon',
    components: {
      CouponCreateStepper,
      CouponDialog,
      ExtendedView,
      CouponList,
      CouponProfile
    },
    data: () => ({
      limit: 100,
      skip: 0,
      addNewCoupon: false,
      couponAdded: null,
      viewList: false,
      selectedCoupon: null,
      addMultipleCoupons: false,
      activeFixedFilters: [
        {
          key: 'active', 
          value: true,
          operator: 'equals',
          type: 'boolean'
        }
      ], 
      inactiveFixedFilters: [
        {
          key: 'active', 
          value: false, 
          operator: 'equals',
          type: 'boolean'
        }
      ], 
      fullyRedeemedFixedFilters: [
        {
          key: 'timesRedeemed',
          dynamicValue: 'maxRedemptions', 
          operator: 'equals', 
          type: 'number'
        },
        {
          key: 'maxRedemptions',
          value: 0,
          operator: 'greaterThan', 
          type: 'number'
        }
      ], 
      unusedFixedFilters: [
        {
          key: 'timesRedeemed',
          value: 0, 
          operator: 'equals', 
          type: 'number'
        }
      ]
    }),
    watch: {
      coupons() {
        this.$store.commit('updateAppBarTabs', this.tabs)
      }
    },
    methods: {
      fixedFilters(key) {
        switch (key) {
          case 'active':
            return [
              {
                key: 'active',
                type: 'boolean',
                value: true,
                operator: 'equals'
              }
            ]
          case 'inactive':
            return [
              {
                key: 'active',
                type: 'boolean',
                value: false,
                operator: 'equals'
              }
            ]
          case 'fully_redeemed':
            return [
              {
                key: 'timesRedeemed',
                dynamicValue: 'maxRedemptions', 
                operator: 'equals', 
                type: 'number'
              },
              {
                key: 'maxRedemptions',
                value: 0,
                operator: 'greaterThan', 
                type: 'number'
              }
            ]
          case 'unused':
            return [
              {
                key: 'timesRedeemed',
                value: 0,
                operator: 'equals',
                type: 'number'
              }
            ]
          default:
            return []
        }
      },
      getCoupons({limit, skip, conditions}) {
        return this.$store.dispatch('getCoupons', {})
      },
      selectCoupon(coupon) {
        this.selectedCoupon = coupon
      },
      createCoupon() {
        this.addNewCoupon = !this.addNewCoupon
      },
      createMultipleCoupons() {
        this.addMultipleCoupons = !this.addMultipleCoupons
      },
      dialogClosed() {
        this.addNewCoupon = !this.addNewCoupon
      },
      couponCreated(payload) {
        this.dialogClosed()
        if(payload.success) {
          this.couponAdded = payload.coupon
          this.$store.commit('updateActionSuccess', {
            message: this.$t('success'),
            subMessage: this.$t('coupon') + ' ' + payload.coupon.label + ' ' + this.$t('wasAddedToYourActiveCoupons')
          })
        }
      },
      exportCoupons() {
        this.$store.commit('updateExportObjects', this.coupons)
      },
      changeView() {
        if(this.viewList) {
          this.$store.commit('updateAppBarChangeDisplay', {
            icon: 'mdi-format-list-bulleted'
          })
        } else {
          this.$store.commit('updateAppBarChangeDisplay', {
            icon: 'mdi-view-day'
          })
        }
        this.viewList = !this.viewList
      }
    },
    computed: {
      callToActions() {
        const actions = []

        actions.push({
          title: 'Create one',
          callback: this.createCoupon
        })

        actions.push({
          title: 'Create multiple',
          callback: this.createMultipleCoupons
        })

        return actions
      },
      showBottomSheet: {
        get() {
          return this.selectedCoupon !== null
        },
        set(value) {
          this.selectedCoupon = null
        }
		  },
      tabs() {
        return [
          {
            title: this.$t('active'),
            key: 'active',
            badge: {
              color: 'green',
              content: this.activeCouponsCount
            }
          },
          {
            title: this.$t('inactive'),
            key: 'inactive',
            badge: {
              color: 'red',
              content: this.inactiveCouponsCount
            }
          },
          {
            title: this.$t('fullyRedeemed'),
            key: 'fully_redeemed',
            badge: {
              color: 'yellow',
              content: this.fullyRedeemedCouponsCount
            }
          },
          {
            title: this.$t('unused'),
            key: 'unused',
            badge: {
              color: 'blue',
              content: this.unusedCouponsCount
            }
          }
          /* { Requires better filters
            title: 'Fully Redeemed',
            key: 'fully_redeemed',
            badge: {
              color: 'blue',
              content: this.fullyRedeemedCouponsCount
            }
          } */
        ]
      },
      selectedAppBarTab: function() {
        return this.$store.state.selectedAppBarTab
      },
      activeCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active)
      },
      disabledCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => !coupon.active)
      },
      unusedCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active && coupon.timesRedeemed === 0)
      },
      fullyRedeemedCoupons() {
        if(this.coupons === null) {
          return null
        }

        return this.coupons.filter(coupon => coupon.active && coupon.maxRedemptions <= coupon.timesRedeemed && coupon.maxRedemptions !== 0)
      },
      coupons: function() {
        if(this.query !== null && this.query.length > 0 ) {

          const query = this.query.reduce((accumulator, item) => accumulator + item.value, '')

          return this.$store.state.coupons.filter(function(coupon) {
            if(coupon !== null && coupon !== undefined) {

              // Checking coupon label
              if(coupon.label !== null && coupon.label !== undefined && coupon.label.toLowerCase().includes(query.toLowerCase())) {
                return true
              }

              // Checking coupon code
              if(coupon.code !== null && coupon.code !== undefined && coupon.code.toLowerCase().includes(query.toLowerCase())) {
                return true
              }

              // Checking coupon item
              if(coupon.item !== null && coupon.item !== undefined) {
                if(coupon.item.title !== null && coupon.item.title !== undefined && coupon.item.title.toLowerCase().includes(query.toLowerCase())) {
                  return true
                }
              }

              // Checking coupon customer
              if(coupon.customer !== null && coupon.customer !== undefined) {
                const customer = coupon.customer
                if(customer.address !== null && customer.address !== undefined) {
                  const customerAddress = customer.address
                  if(customerAddress.name_line !== null && customerAddress.name_line !== undefined && customerAddress.name_line.toLowerCase().includes(query.toLowerCase())) {
                    return true
                  }
                }
              }
            }

            return false
          }.bind(this))
        }

        return this.$store.state.coupons
      },
      activeCouponsCount() {
        return this.activeCoupons !== null ? this.activeCoupons.length : 0
      },
      inactiveCouponsCount() {
        return this.disabledCoupons !== null ? this.disabledCoupons.length : 0
      },
      unusedCouponsCount() {
        return this.unusedCoupons !== null ? this.unusedCoupons.length : 0
      },
      fullyRedeemedCouponsCount() {
        return this.fullyRedeemedCoupons !== null ? this.fullyRedeemedCoupons.length : 0
      },
      query() {
        return this.$store.state.searchQuery
      },
    },
    created() {
      this.$store.commit('updateAppBarTabs', this.tabs)
      this.$store.commit('updateSelectedAppBarTab', this.tabs[0].key)

      this.$store.commit('updateAppBarFabs', this.callToActions)
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportCoupons
      })
      this.$store.commit('updateAppBarChangeDisplay', {
        icon: 'mdi-format-list-bulleted',
        callback: this.changeView
      })
      this.$store.dispatch('getCoupons')
    },
    updated() {
      this.$store.commit('updateAppBarTabs', this.tabs)

      this.$store.commit('updateAppBarFabs', this.callToActions)
      this.$store.commit('updateAppBarExport', {
        color: 'pink',
        callback: this.exportCoupons
      })
      this.$store.commit('updateAppBarChangeDisplay', {
        icon: 'mdi-format-list-bulleted',
        callback: this.changeView
      })
    },
    destroyed() {
      // Remove all this view specific settings
      this.$store.commit('updateAppBarTabs', null)
      this.$store.commit('updateAppBarFabs', null)
      this.$store.commit('updateAppBarExport', null)
      this.$store.commit('updateAppBarChangeDisplay', null)
    }
  }
</script>
